<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.order_no"
          size="small"
          clearable
          placeholder="按订单号查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.pay_status"
          placeholder="按支付状态查询"
          clearable
          size="small"
        >
          <el-option
            label="未支付"
            :value="10"
          >
          </el-option>
          <el-option
            label="已支付"
            :value="20"
          >
          </el-option>
          <el-option
            label="已取消"
            :value="30"
          >
          </el-option>
          <el-option
            label="已退款"
            :value="40"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="order_no"
        title="订单号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="u_type"
        title="用户信息"
        align="center"
        min-width="100px"
      >
        <template v-slot="{ row }">
          <span v-if="row.u_type==10">微信-{{row.nickname||'匿名用户'}}</span>
          <span v-if="row.u_type==20">电卡-{{row.cardno}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="device_id"
        title="充值设备"
        align="center"
        min-width="200px"
      >
        <template v-slot="{ row }">
          <div v-if="!row.device_id">无</div>
          <div v-if="row.device_id">{{row.device_name}}</div>
          <div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}</div>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="order_price"
        title="充值金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="pay_price"
        title="实际支付金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="gift_money"
        title="赠送金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="pay_status"
        title="支付状态"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.pay_status[row.pay_status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="for_status"
        title="到账状态"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.for_status[row.for_status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="pay_time"
        title="付款时间"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="90"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="detailOne(row)"
            v-if="$hasAccess('cards.recharge/view')"
          >详情</el-button>
          <!-- <el-button
            size="small"
            plain
            @click="refundOne(row)"
            v-if="row.pay_status==20&&$hasAccess('user.recharge/refund')"
          >退款</el-button> -->
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 详情 -->
    <el-dialog
      title="充值详情"
      :visible.sync="detailDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
    >
      <el-form
        ref="detailForm"
        label-width="140px"
        class="form"
        size="mini"
      >
        <el-row>
          <el-col
            :xs="{span:24,offset:0}"
            :sm="{span:10,offset:1}"
          >
            <!-- 订单信息 -->
            <div class="el-block">
              <div class="row-title">订单信息</div>
              <el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
              <el-form-item label="订单状态：">{{types.pay_status[detailDialogFormData.pay_status]||'未知状态'}}</el-form-item>
              <el-form-item label="到账状态：">{{types.for_status[detailDialogFormData.for_status]||'未知状态'}}</el-form-item>
              <el-form-item label="充值用户：">{{detailDialogFormData.u_type==10?'微信-'+(detailDialogFormData.nickname||'匿名用户'):'电卡-'+detailDialogFormData.cardno}}</el-form-item>
              <el-form-item label="充值金额：">{{detailDialogFormData.order_price}}元</el-form-item>
              <el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
              <el-form-item label="支付时间：">{{detailDialogFormData.pay_status==20?detailDialogFormData.pay_time:'未支付'}}</el-form-item>
            </div>
          </el-col>
          <el-col
            :xs="{span:24,offset:0}"
            :sm="{span:10,offset:2}"
          >
            <!-- 优惠信息 -->
            <div class="el-block">
              <div class="row-title">优惠信息</div>
              <el-form-item label="充值优惠ID：">{{detailDialogFormData.plan_id}}</el-form-item>
              <el-form-item label="赠送金额：">{{detailDialogFormData.gift_money}}元</el-form-item>
            </div>
            <!-- 代理信息 -->
            <div class="el-block">
              <div class="row-title">代理信息</div>
              <el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
              <el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
              <el-form-item label="分销结算状态：">{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
            </div>
            <!-- 设备信息 -->
            <div class="el-block">
              <div class="row-title">设备信息</div>
              <el-form-item label="设备名称：">{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}</el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 退款 -->
    <!-- <el-dialog
      title="充值退款"
      :visible.sync="refundDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="400px"
    >
      <el-form
        :model="refundDialogFormData"
        :rules="refundRules"
        size="mini"
        ref="refundForm"
        label-width="120px"
        class="form"
      >
        <el-form-item label="订单号：">{{refundDialogFormOriData.order_no}}</el-form-item>
        <el-form-item label="实际付款金额：">{{refundDialogFormOriData.pay_price}}元</el-form-item>
        <el-form-item
          label="退款金额："
          prop="refund_price"
        >
          <el-input-number
            v-model="refundDialogFormData.refund_price"
            size="small"
            style="width: 80%;"
            controls-position="right"
            :min="0.01"
            :max="refundDialogFormOriData.pay_price"
            :step="1"
            :precision="2"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveRefund()"
          v-if="refundDialogFormOriData.pay_status==20&&$hasAccess('user.recharge/refund')"
        >确 认 退 款
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'user-recharge-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '充值列表',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        u_type: 20
      },
      types: {
        pay_status: {
          10: '未支付',
          20: '已支付',
          30: '已取消',
          40: '已退款'
        },
        share_status: {
          10: '不结算',
          20: '未结算',
          30: '已结算'
        },
        for_status: {
          10: '未到账',
          20: '已到账'
        }
      },
      // 详情
      detailDialogShow: false,
      detailDialogFormData: {},
      // 退款
      refundDialogShow: false,
      refundDialogFormData: {},
      refundDialogFormOriData: {},
      refundRules: {
        refund_price: [
          { required: true, message: '请填写退款金额', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      // 小区限制
      const group_id = this.$store.state.user.userInfo.group_id
      if (!searchData.group_id && group_id) {
        searchData.group_id = group_id
      }
      const res = await this.$api.Fans.GetRechargeList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 20
      }
    },
    // 关闭弹窗
    closeDialog () {
      this.detailDialogShow = false
      this.refundDialogShow = false
    },
    // 详情
    async detailOne (row) {
      const params = {
        token: this.$store.state.user.token,
        order_id: row.order_id
      }
      const res = await this.$api.Fans.GetRechargeDetail(params)
      this.detailDialogFormData = res
      this.detailDialogShow = true
    },
    // 退款
    refundOne (row) {
      this.refundDialogFormData = {
        order_id: row.order_id,
        refund_price: row.pay_price
      }
      this.refundDialogFormOriData = {
        pay_price: Number(row.pay_price),
        pay_status: row.pay_status,
        order_no: row.order_no
      }
      this.refundDialogShow = true
    },
    // 退款保存
    saveRefund () {
      this.$refs.refundForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.refundDialogFormData)
          }
          await this.$api.Fans.RefundRecharge(params)
          this.$notify({
            title: '成功',
            message: '退款成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.row-title {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0;
  margin-bottom: 18px;

  &:before {
    content: " ";
    width: 5px;
    height: 20px;
    background: #008e4d;
    display: block;
    float: left;
    margin: 0 10px 0 0;
  }
}
</style>
